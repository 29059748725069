// Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
// License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
export const Key = () => <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512">
    <path d="M282.3 343.7L248.1 376.1C244.5 381.5 238.4 384 232 384H192V424C192
    437.3 181.3 448 168 448H128V488C128 501.3 117.3 512 104 512H24C10.75 512 0
    501.3 0 488V408C0 401.6 2.529 395.5 7.029 391L168.3 229.7C162.9 212.8 160
    194.7 160 176C160 78.8 238.8 0 336 0C433.2 0 512 78.8 512 176C512 273.2 433.2
    352 336 352C317.3 352 299.2 349.1 282.3 343.7zM376 176C398.1 176 416 158.1
    416 136C416 113.9 398.1 96 376 96C353.9 96 336 113.9 336
    136C336 158.1 353.9 176 376 176z"/>
</svg>
