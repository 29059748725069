export interface LangStruct {
    RegisterChannelsHeader: string
    ChannelCardActive: string
    ChannelCardInactive: string
    ChannelCardRead: string
    ChannelCardWrite: string
    ChannelCardAdd: string
    SomethingWentWrongError: string
    ChannelSettingsMenuMainSettings: string
    ChannelSettingsMenuKeys: string
    ChannelSettingsMenuMixins: string
    MixinsIn: string
    MixinsOut: string
    WrongKeyError: string
    ReadKeyForm: string
    CreateMixinButton: string
    YourMixinsHeader: string
    ChannelNameForm: string
    ChannelNameTooLong: string
    RenameChannelButton: string
    KeyNameTooLongError: string
    KeyNameForm: string
    KeyTypeReadForm: string
    KeyTypeWriteForm: string
    KeyAllowInfoForm: string
    KeyAllowMixinsForm: string
    CreateKeyButton: string
    Paused: string,
    PauseKeyButton: string
    ResumeKeyButton: string
    DeleteKeyButton: string
    DeleteMixinConfirmQuestion: string
    DeleteMixinButton: string
    NoMixinInCard: string
    NoMixinOutCard: string
    CreateChannelButton: string
    LoginButton: string
    ExitButton: string
    SettingsButton: string
    PasswordTooShortError: string
    InvalidEmailError: string
    EmailForm: string
    PasswordForm: string
    RememberMeForm: string
    PasswordsNotMatchError: string
    UsernameTooLongError: string
    UsernameForm: string
    PasswordAgainForm: string
    RegisterButton: string
    UserSettingsMenuUsername: string
    UserSettingsMenuEmail: string
    UserSettingsMenuPassword: string
    ChangeEmailButton: string
    RenameUserButton: string
    OldPasswordForm: string
    NewPasswordForm: string
    NewPasswordAgainForm: string
    ChangePasswordButton: string
    CreateKeyHeader: string
    RenameChannelHeader: string
    CreateMixinHeader: string
    YourKeysHeader: string
    DeleteKeyConfirmQuestion: string
    RenameUserHeader: string
    ChangeEmailHeader: string
    ChangePasswordHeader: string
    MainSettings: string
    ChangeLanguage: string
    ChangeTheme: string
    DarkTheme: string
    LightTheme: string
    SystemTheme: string
    English: string
    Russian: string

    EnterEmail: string
    EnterPassword: string
    EnterUsername: string
    EnterPasswordAgain: string
    EnterOldPassword: string
    EnterNewPassword: string
    EnterNewPasswordAgain: string
    EnterKeyName: string
    EnterReadKey: string
    EnterChannelName: string
    LoginHeader: string
    RegisterHeader: string
    UserSettingsHeader: string
    ChannelSettingsHeader: string
    CreateChannelHeader: string

    ChannelNameError: string,
    ChannelNotExistError: string,
    NotChannelOwnerError: string,

    KeyNameError: string,
    KeyPermissionsError: string,
    BadChannelIdError: string,
    BadKeyError: string,

    AlreadyMixedError: string
    BadThreadError: string
    BadKeyTypeError: string
    CircleMixinError: string
    SelfMixinError: string

    EmailError: string
    UsernameError: string
    PasswordError: string
    EmailExistError: string
    BadUserError: string
    UnknownError: string

    GreetingHeader: string
    GreetingText: string
    LearnMoreButton: string
    TrySandbox: string
}

export const defaultLangValue: LangStruct = {
    RegisterChannelsHeader: 'RegisterChannelsHeader',
    ChannelCardActive: 'ChannelCardActive',
    ChannelCardInactive: 'ChannelCardInactive',
    ChannelCardRead: 'ChannelCardRead',
    ChannelCardWrite: 'ChannelCardWrite',
    ChannelCardAdd: 'ChannelCardAdd',
    SomethingWentWrongError: 'SomethingWentWrongError',
    ChannelSettingsMenuMainSettings: 'ChannelSettingsMenuMainSettings',
    ChannelSettingsMenuKeys: 'ChannelSettingsMenuKeys',
    ChannelSettingsMenuMixins: 'ChannelSettingsMenuMixins',
    MixinsIn: 'MixinsIn',
    MixinsOut: 'MixinsOut',
    WrongKeyError: 'WrongKeyError',
    ReadKeyForm: 'ReadKeyForm',
    CreateMixinButton: 'CreateMixinButton',
    YourMixinsHeader: 'YourMixinsHeader',
    ChannelNameForm: 'ChannelNameForm',
    ChannelNameTooLong: 'ChannelNameTooLong',
    RenameChannelButton: 'RenameChannelButton',
    KeyNameTooLongError: 'KeyNameTooLongError',
    KeyNameForm: 'KeyNameForm',
    KeyTypeReadForm: 'KeyTypeReadForm',
    KeyTypeWriteForm: 'KeyTypeWriteForm',
    KeyAllowInfoForm: 'KeyAllowInfoForm',
    KeyAllowMixinsForm: 'KeyAllowMixinsForm',
    CreateKeyButton: 'CreateKeyButton',
    Paused: 'Paused',
    PauseKeyButton: 'PauseKeyButton',
    ResumeKeyButton: 'ResumeKeyButton',
    DeleteKeyButton: 'DeleteKeyButton',
    DeleteMixinConfirmQuestion: 'DeleteMixinConfirmQuestion',
    DeleteMixinButton: 'DeleteMixinButton',
    NoMixinInCard: 'NoMixinInCard',
    NoMixinOutCard: 'NoMixinOutCard',
    CreateChannelButton: 'CreateChannelButton',
    LoginButton: 'LoginButton',
    ExitButton: 'ExitButton',
    SettingsButton: 'SettingsButton',
    PasswordTooShortError: 'PasswordTooShortError',
    InvalidEmailError: 'InvalidEmailError',
    EmailForm: 'EmailForm',
    PasswordForm: 'PasswordForm',
    RememberMeForm: 'RememberMeForm',
    PasswordsNotMatchError: 'PasswordsNotMatchError',
    UsernameTooLongError: 'UsernameTooLongError',
    UsernameForm: 'UsernameForm',
    PasswordAgainForm: 'PasswordAgainForm',
    RegisterButton: 'RegisterButton',
    UserSettingsMenuUsername: 'UserSettingsMenuUsername',
    UserSettingsMenuEmail: 'UserSettingsMenuEmail',
    UserSettingsMenuPassword: 'UserSettingsMenuPassword',
    ChangeEmailButton: 'ChangeEmailButton',
    RenameUserButton: 'RenameUserButton',
    OldPasswordForm: 'OldPasswordForm',
    NewPasswordForm: 'NewPasswordForm',
    NewPasswordAgainForm: 'NewPasswordAgainForm',
    ChangePasswordButton: 'ChangePasswordButton',
    CreateKeyHeader: 'CreateKeyHeader',
    RenameChannelHeader: 'RenameChannelHeader',
    CreateMixinHeader: 'CreateMixinHeader',
    YourKeysHeader: 'YourKeysHeader',
    DeleteKeyConfirmQuestion: 'DeleteKeyConfirmQuestion',
    RenameUserHeader: 'RenameUserHeader',
    ChangeEmailHeader: 'ChangeEmailHeader',
    ChangePasswordHeader: 'ChangePasswordHeader',
    MainSettings: 'MainSettings',
    ChangeLanguage: 'ChangeLanguage',
    ChangeTheme: 'ChangeTheme',
    DarkTheme: 'DarkTheme',
    LightTheme: 'LightTheme',
    SystemTheme: 'SystemTheme',
    English: 'English',
    Russian: 'Russian',

    EnterEmail: 'EnterEmail',
    EnterPassword: 'EnterPassword',
    EnterUsername: 'EnterUsername',
    EnterPasswordAgain: 'EnterPasswordAgain',
    EnterOldPassword: 'EnterOldPassword',
    EnterNewPassword: 'EnterNewPassword',
    EnterNewPasswordAgain: 'EnterNewPasswordAgain',
    EnterKeyName: 'EnterKeyName',
    EnterReadKey: 'EnterReadKey',
    EnterChannelName: 'EnterChannelName',
    LoginHeader: 'LoginHeader',
    RegisterHeader: 'RegisterHeader',
    UserSettingsHeader: 'UserSettingsHeader',
    ChannelSettingsHeader: 'ChannelSettingsHeader',
    CreateChannelHeader: 'CreateChannelHeader',


    ChannelNameError: 'ChannelNameError',
    ChannelNotExistError: 'ChannelNotExistError',
    NotChannelOwnerError: 'NotChannelOwnerError',

    KeyNameError: 'KeyNameError',
    KeyPermissionsError: 'KeyPermissionsError',
    BadChannelIdError: 'BadChannelIdError',
    BadKeyError: 'BadKeyError',

    AlreadyMixedError: 'AlreadyMixedError',
    BadThreadError: 'BadThreadError',
    BadKeyTypeError: 'BadKeyTypeError',
    CircleMixinError: 'CircleMixinError',
    SelfMixinError: 'SelfMixinError',

    EmailError: 'EmailError',
    UsernameError: 'UsernameError',
    PasswordError: 'PasswordError',
    EmailExistError: 'EmailExistError',
    BadUserError: 'BadUserError',
    UnknownError: 'UnknownError',

    GreetingHeader: 'GreetingHeader',
    GreetingText: 'GreetingText',
    LearnMoreButton: 'LearnMoreButton',
    TrySandbox: 'TryPlayground'
}
